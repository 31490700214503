<template>
  <section
    v-if="faqBlock && faqBlock.length"
    class="sa-faq"
    data-test="faq-block"
    itemscope
    itemtype="https://schema.org/FAQPage"
  >
    <div class="container">
      <h2
        class="sa-faq__title"
        :class="customClass"
      >
        {{ title.replace("%CITY_LOC%", getAddressText.cityOnlyLoc) }}
      </h2>

      <VExpansionPanels
        v-model="expandFAQ"
        variant="accordion"
        multiple
        static
        elevation="0"
        class="sa-faq__epanel"
      >
        <VExpansionPanel
          v-for="(item, index) in faqBlock"
          :key="'faq' + index"
          eager
          hide-actions
          static
          elevation="0"
          class="sa-faq__epanel-item"
          itemprop="mainEntity"
          itemscope
          itemtype="https://schema.org/Question"
        >
          <template #title>
            <span
              class="subhead-2"
              itemprop="name"
            >{{
              textAgg
                ? textAggReplace(
                  item.question,
                  getAddressText,
                  textAgg,
                  provider,
                )
                : item.question
            }}
            </span><img
              loading="lazy"
              src="@/assets/images/svg/arrow-expand.svg"
              alt="arrow"
            >
          </template>
          <template #text>
            <div
              class="title-5 gray-dark--text"
              itemprop="acceptedAnswer"
              itemscope=""
              itemtype="http://schema.org/Answer"
            >
              <div
                itemprop="text"
                v-html="
                  textAgg
                    ? textAggReplace(item.answer, getAddressText, textAgg, provider)
                    : item.answer
                "
              />
            </div>
          </template>
        </VExpansionPanel>
      </VExpansionPanels>
    </div>
  </section>
</template>

<script setup lang="ts">
import { VExpansionPanel, VExpansionPanels } from 'vuetify/components/VExpansionPanel'
import { textAggReplace } from '~/helpers'
import { useCities } from '~/store/cites/cities.store'

defineProps({
  faqBlock: {
    type: Array,
    default() {
      return []
    },
  },
  title: {
    type: String,
    default: 'Вопросы и ответы по подключению',
  },
  customClass: {
    type: String,
    default: 'headline-3',
  },
  textAgg: {},
  provider: {},
})

const cityStore = useCities()
const expandFAQ = ref([])

const getAddressText = computed(() => cityStore.getAddressText)
</script>

<style scoped lang="scss">
.sa-faq {
  padding-top: 8px;
  padding-bottom: 8px;
  &__title {
    margin-bottom: 16px;
    .secondary-dark--text {
      transition: color 0.24s;
      cursor: pointer;
      &:hover {
        color: color(primary);
      }
    }
  }
  &__epanel {

    &-item {
      border: 1px solid color(gray-light);
      margin-bottom: 4px;
      img {
        transition: transform .24s;
        width: 24px;
        height: 24px;
      }
      &:deep(.v-expansion-panel-title--active) {
        img {
          transform: rotate(180deg);
        }
      }
    }
  }
  &:deep(.v-expansion-panels--variant-accordion) {
    .v-expansion-panel {
      border-radius: 12px !important;
    }
  }
}
</style>
